import WDHFeatureB2BCartSpot from '../../../../wdh-feature/wdh-feature-b2b/src/js/cart-spot';
(function ($, DGS) {
  var li = $('li .shopping-cart');
  if (li.length > 0) {
    // let logoParent = $('#header .section-one .component-content')[0];
    // let logo = document.createElement('img');
    // logo["src"] = "http://assets.dev.cas.dgs.com/-/media/hearing-solutions/images/global/template/demant-white-logo.png?h=468&la=en&w=2268&rev=4462&hash=42732D9EFFB3F1C92E4E9F3E957BB847B4A5417F";
    // //logo["width"] = "150";
    // let logoDiv = document.createElement('div');
    // logoDiv.classList.add('demant-logo');
    // logoDiv.append(logo);
    // logoParent.prepend(logoDiv);

    var div = document.createElement('div');
    div.classList.add('component');
    div.classList.add('wdh-feature-b2b-cartspot');
    div.innerHTML = '<div class="component-content"></div>';
    li.parent().append(div);
    li.remove();
    new WDHFeatureB2BCartSpot().run();
  }
  DGS.OnLoad.getInstance().register(function () {
    var DESKTOP_BP = window.matchMedia("(min-width: 768px)");
    var MOBILE_BP = window.matchMedia("(max-width: 767px)");
    var mobileNavClosed = true;
    var dictionary = {
      Navigation: {
        BackButtonText: "Back"
      },
      Logo: {
        textUnderLogo: "Home"
      }
    };
    var language = $('html').attr('lang');
    DGS.Resizer.getInstance().register(function () {
      clearOnResize();
    });
    function clearOnResize() {
      var burger = getMobileNavBurger();
      var closeBtn = getMobileNavCloseBtn();
      var navFirstLevel = getNavFirstLevel();
      var backBtn = $('.nav-back-btn-container');
      var thirdNavLevelHeader = $('.level3.nav-header-container');
      closeBtn.hide();
      burger.show();
      navFirstLevel.removeClass('active-mobile-nav');
      hideSecondLevelNav();
      clearFirstLevelNavHighlight();
      if (DESKTOP_BP.matches) {
        backBtn.hide();
        thirdNavLevelHeader.hide();
      }
      if (MOBILE_BP.matches) {
        backBtn.show();
        thirdNavLevelHeader.show();
      }
    }
    DGS.Dictionary.getInstance().getTexts(dictionary, function () {
      manageFirstLevelNav();
      buildLogoBackground();
    }, language);
    function manageFirstLevelNav() {
      $('.level1 > a').each(function () {
        var _this = this;
        $(this).on('click', function (e) {
          e.preventDefault();
          var SELF = $(_this);
          if (SELF.hasClass('active') && !MOBILE_BP.matches) {
            hideSecondLevelNav();
          } else {
            buildSecondLevelNav(SELF);
            dismantleSecondLevelNav();
            highlightCurrentUrlInNav();
          }
        });
      });
      manageMenuAccessibility();
    }
    function buildSecondLevelNav(el) {
      hideScroll();
      deactivateNavLinks();
      highlightActiveNavLink(el);
      deactivateNavLists($('.level1 ul'));
      showActiveNavList(el);
      addHeaderToSecondLevelNav();
      clearHighLightedNavStyling(el);
      manageSecondLevelNav();
      if (MOBILE_BP.matches) {
        buildBackBtnToMobileSecondLevelNav();
        getCloseBtnOfSecondLevelNav().hide();
        highlightCurrentUrlInNav();
      }
    }
    function dismantleSecondLevelNav() {
      var closeBtn = getCloseBtnOfSecondLevelNav();
      closeBtn.on('click', function (e) {
        e.preventDefault();
        hideSecondLevelNav();
      });
      document.onkeydown = function (evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ('key' in evt) {
          isEscape = evt.key === 'Escape' || evt.key === 'Esc';
        } else {
          isEscape = evt.keyCode === 27;
        }
        if (isEscape) {
          hideSecondLevelNav();
        }
      };
      var backBtn = getBackBtnToSecondLevelNav();
      backBtn.on('click', function () {
        deactivateNavLinks();
        hideSecondLevelNavOnMobile();
        clearFirstLevelNavHighlight();
        highlightCurrentUrlInNav();
      });
    }
    function highlightCurrentUrlInNav() {
      var links = getLinksOfCurrentUrl();
      $.each(links, function (index, link) {
        var linkContainer = link.parent();
        var activeListLinks = linkContainer.siblings().find('> a');
        if (index === 0) {
          var siblingLinkIsActive = linkContainer.siblings().find('> a.active').length > 0;
          if (siblingLinkIsActive) {
            link.removeClass('active');
            clearFirstLevelNavHighlight();
          }
          if (MOBILE_BP.matches && !siblingLinkIsActive) {
            highlightActiveNavLink(link);
            highlightCurrentUrlLink(linkContainer, activeListLinks);
          }
        }
        if (index === 1) {
          highlightCurrentUrlLink(linkContainer, activeListLinks);
          if (MOBILE_BP.matches && links.length > 2) {
            highlightClickedNavLink(activeListLinks, link);
          }
          if (!MOBILE_BP.matches && links.length > 2) {
            manageThirdLevelNav(links[1], link);
            dismantleThirdLevelNav();
          }
        }
        if (index === 2) {
          highlightCurrentUrlLink(linkContainer, activeListLinks);
        }
      });
    }
    function hideScroll() {
      mobileNavClosed = false;
      $('body').css({
        'overflow': 'hidden',
        'position': 'fixed'
      });
    }
    function deactivateNavLinks() {
      $('.level1 a').removeClass('active');
    }
    function highlightActiveNavLink(el) {
      el.addClass('active');
    }
    function deactivateNavLists(el) {
      el.removeClass('active');
    }
    function showActiveNavList(el) {
      el.siblings('ul').toggleClass('active');
    }
    function addHeaderToSecondLevelNav() {
      buildHeaderToSecondLevelNav();
      addCloseBtnToSecondLevelNav();
    }
    function clearHighLightedNavStyling(el) {
      var allLinksOfLevel2 = el.parent().find('.level2 a');
      var allLinks = el.parent().find('a');
      allLinks.removeClass('active-nav-link');
      allLinksOfLevel2.removeClass('fadedNavLinks highlightLink active-nav-link');
    }
    function manageSecondLevelNav() {
      var activeListLinks = getSecondLevelNavLinks();
      activeListLinks.each(function () {
        var _this2 = this;
        $(this).click(function () {
          var self = $(_this2);
          activeListLinks.removeClass('highlightLink');
          fadeUnclikedNavLinks(activeListLinks, self);
          manageArrowRight(activeListLinks, self);
          manageThirdLevelNav(activeListLinks, self);
          dismantleThirdLevelNav();
        });
      });
      disableSecondLevelLinksWithThirdLevel(activeListLinks);
    }
    function buildBackBtnToMobileSecondLevelNav() {
      var BtnContainer = $('#header .level1 > ul.active');
      var btn = getBackBtnToSecondLevelNav();
      var btnText = dictionary.Navigation.BackButtonText;
      buildBackBtnToMobileNav(BtnContainer, btn, btnText, 2);
    }
    function getCloseBtnOfSecondLevelNav() {
      return $('.level1 ul.active .level2.nav-header-container .close-icon');
    }
    function hideSecondLevelNav() {
      showScroll();
      deactivateNavLinks();
      deactivateNavLists($('.level1 ul'));
      allSubmenusAreClosed();
    }
    function getBackBtnToSecondLevelNav() {
      var backBtn = $('#header .level1 > ul.active .level2.nav-back-btn');
      return backBtn;
    }
    function hideSecondLevelNavOnMobile() {
      var activeNavList = $('#header .level1 > ul.active');
      hideNavLevelOnMobile(activeNavList);
    }
    function clearFirstLevelNavHighlight() {
      $('.level1 > a').removeClass('fadedNavLinks highlightLink active-nav-link');
    }
    function getLinksOfCurrentUrl() {
      var language = document.documentElement.lang;
      var urlParts = window.location.pathname.split('/').slice(1);
      var links = [];
      $.each(urlParts, function (index) {
        var linkUrl = '';
        var i;
        for (i = 0; i <= index; i++) {
          linkUrl += '/' + urlParts[i];
        }
        var link = $('.navigation a[href="' + linkUrl + '"]');
        links.push(link);
      });
      if (language !== 'en') {
        links.shift();
      }
      return links;
    }
    function highlightCurrentUrlLink(link, links) {
      fadeUnclikedNavLinks(links, link);
      addArrowRight(link);
    }
    function buildHeaderToSecondLevelNav() {
      var headerContainer = $('#header .level1 > ul.active');
      var header = getHeaderToSecondLevelNav();
      var headerText = $('.level1 a.active')[0].innerText;
      buildHeaderToNavLevel(headerContainer, header, headerText, 2);
    }
    function addCloseBtnToSecondLevelNav() {
      var closeBtn = getCloseBtnOfSecondLevelNav();
      var closeBtnContainer = getHeaderToSecondLevelNav().parent();
      addCloseBtn(closeBtn, closeBtnContainer);
    }
    function getSecondLevelNavLinks() {
      return $('ul.active li.level2 > a');
    }
    function disableSecondLevelLinksWithThirdLevel(links) {
      links.each(function () {
        var _this3 = this;
        $(this).click(function (e) {
          var thirdLevelNav = $(_this3).siblings('ul');
          if (checkIfElementExists(thirdLevelNav)) {
            e.preventDefault();
          }
        });
      });
    }
    function fadeUnclikedNavLinks(links, clickedLink) {
      links.addClass('fadedNavLinks').removeClass('active-nav-link');
      clickedLink.removeClass('fadedNavLinks active-nav-link');
    }
    function manageArrowRight(links, clickedLink) {
      var activeThirdLevel = clickedLink.siblings('ul');
      links.removeClass('active-nav-link');
      if (checkIfElementExists(activeThirdLevel)) {
        clickedLink.addClass('active-nav-link');
      }
    }
    function manageThirdLevelNav(links, clickedLink) {
      var thirdLevelNav = clickedLink.siblings('ul');
      var thirdLevelNavLinks = links.siblings('ul').find('li a');
      if (checkIfElementExists(thirdLevelNav)) {
        highlightClickedNavLink(links, clickedLink);
        showActiveThirdLevelNav(links, clickedLink);
        thirdLevelNavLinks.each(function () {
          var _this4 = this;
          $(this).click(function () {
            fadeUnclikedNavLinks(thirdLevelNavLinks, $(_this4));
          });
        });
        if (MOBILE_BP.matches) {
          buildHeaderToThirdLevelNav(clickedLink);
          buildBackBtnToMobileThirdLevelNav(clickedLink);
        }
      } else {
        $('.level2 > ul.active').removeClass('active');
      }
    }
    function dismantleThirdLevelNav() {
      var backBtn = $('#header .level1 > ul.active .level2 > ul.active .level3.nav-back-btn');
      var activeFirstLevel = $('li.level1 > a.active');
      backBtn.on('click', function () {
        hideThirdLevelNavOnMobile();
        clearHighLightedNavStyling(activeFirstLevel);
        highlightCurrentUrlInNav();
      });
    }
    function buildBackBtnToMobileNav(container, btn, btnText, navLevel) {
      var btnHTML = "<li class='level" + navLevel + " nav-back-btn-container'><div class='level" + navLevel + " nav-back-btn'><p><span class='arrow-left-icon'></span>" + btnText + "</p></div></li>";
      if (checkIfElementExists(btn)) {
        btn.show();
      } else {
        container.prepend(btnHTML);
      }
    }
    function showScroll() {
      mobileNavClosed = true;
      $('body').css({
        'overflow': 'visible',
        'position': 'static'
      });
    }
    function hideNavLevelOnMobile(activeNav) {
      activeNav.addClass('slide-out-to-right');
      setTimeout(function () {
        deactivateNavLists(activeNav);
        activeNav.removeClass('slide-out-to-right');
      }, 500);
    }
    function buildHeaderToThirdLevelNav(el) {
      var headerContainer = el.parent().find('ul');
      var header = el.parent().find('ul .level3.nav-back-btn');
      var headerText = el[0].innerText;
      buildHeaderToNavLevel(headerContainer, header, headerText, 3);
    }
    function getHeaderToSecondLevelNav() {
      var header = $('#header .level1 > ul.active .level2.nav-header');
      return header;
    }
    function addCloseBtn(btn, btnContainer) {
      if (checkIfElementExists(btn)) {
        btn.show();
      } else {
        btnContainer.prepend('<button class="close-icon" aria-label="Close"></button>');
      }
    }
    function checkIfElementExists(element) {
      var elementExists = element.length > 0;
      return elementExists;
    }
    function addArrowRight(container) {
      container.children('a').addClass('active-nav-link');
    }
    function buildBackBtnToMobileThirdLevelNav(el) {
      var BtnContainer = el.parent().find('ul');
      var btn = el.parent().find('ul .level3.nav-back-btn');
      var btnText = $('.level1 a.active')[0].innerText;
      buildBackBtnToMobileNav(BtnContainer, btn, btnText, 3);
    }
    function hideThirdLevelNavOnMobile() {
      var activeNavList = $('#header ul.active > .level2 > ul');
      hideNavLevelOnMobile(activeNavList);
    }
    function highlightClickedNavLink(links, clickedLink) {
      links.removeClass('highlightLink');
      clickedLink.addClass('highlightLink');
    }
    function showActiveThirdLevelNav(links, clickedLink) {
      var thirdLevelNav = links.siblings('ul');
      var activeThirdLevelNav = clickedLink.siblings('ul');
      thirdLevelNav.removeClass('active');
      activeThirdLevelNav.addClass('active');
    }
    function buildHeaderToNavLevel(container, header, headerText, navLevel) {
      var headerHTML = "<li class='level" + navLevel + " nav-header-container'><h3 class='level" + navLevel + " nav-header'>" + headerText + "</h3></li>";
      if (checkIfElementExists(header)) {
        header.show();
      } else {
        container.prepend(headerHTML);
      }
    }
    function buildLogoBackground() {
      var logoLink = getLogoLink();
      if (logoLink.selector != null && logoLink.selector.indexOf('section-two') >= 0) {
        var logoBackground = '<div class="shapegradient"><p></p></div>';
        logoLink.append(logoBackground);
      }
    }
    function getLogo() {
      return $('#header .section-one .image.component');
    }
    function getLogoLink() {
      var logoLink = $('#header .section-two .image .component-content a');
      return logoLink;
    }
    manageSearchComponent();
    function manageSearchComponent() {
      addOpenSearchBtn();
      getOpenSearchBtn().on('click', function () {
        buildSearchFieldInput();
        dismantleSearchFieldInput();
      });
    }
    function addOpenSearchBtn() {
      var openSearchBtn = getOpenSearchBtn();
      var openSearchBtnContainer = $('#header .search-field-spot .search-field');
      if (checkIfElementExists(openSearchBtn)) {
        openSearchBtn.show();
      } else {
        openSearchBtnContainer.prepend('<button class="search-open-btn" aria-label="Search"></button>');
      }
    }
    function getOpenSearchBtn() {
      var openSearchBtn = $('#header .search-field-spot .search-field .search-open-btn');
      return openSearchBtn;
    }
    function buildSearchFieldInput() {
      getSearchField().addClass('active-search');
      showSearchFieldInput();
      setCursorInSearchInput();
      addCloseIconToSearchField();
      getLogo().addClass('activeMobileSearch');
    }
    function dismantleSearchFieldInput() {
      getCloseBtn().on('click', function () {
        getSearchFieldInput().hide();
        getSearchField().removeClass('active-search');
        getCloseBtn().hide();
        getLogo().removeClass('activeMobileSearch');
      });
    }
    function getSearchField() {
      return $('#header .search-field');
    }
    function showSearchFieldInput() {
      getSearchFieldInput().show();
    }
    function setCursorInSearchInput() {
      var searchInput = getSearchFieldInput();
      var searchInputValue = searchInput.val();
      searchInput.focus();
      searchInput.val('').val(searchInputValue);
    }
    function addCloseIconToSearchField() {
      var closeBtn = getCloseBtn();
      var closeBtnContainer = getSearchField();
      addCloseBtn(closeBtn, closeBtnContainer);
    }
    function getSearchFieldInput() {
      return $('#header .search-field input');
    }
    function getCloseBtn() {
      return $('#header .search-field .close-icon');
    }
    manageMobileNav();
    function manageMobileNav() {
      buildMobileNavBtns();
      showMobileNavMenu();
      hideMobileNavMenu();
    }
    function buildMobileNavBtns() {
      buildMobileNavBurger();
      buildMobileNavCloseBtn();
      var closeBtn = getMobileNavCloseBtn();
      closeBtn.hide();
    }
    function showMobileNavMenu() {
      var burger = getMobileNavBurger();
      var closeBtn = getMobileNavCloseBtn();
      var navFirstLevel = getNavFirstLevel();
      burger.on('click', function (e) {
        navFirstLevel.addClass('active-mobile-nav');
        hideScroll();
        burger.hide();
        closeBtn.show();
        highlightCurrentUrlInNav();
      });
    }
    function hideMobileNavMenu() {
      var burger = getMobileNavBurger();
      var closeBtn = getMobileNavCloseBtn();
      var navFirstLevel = getNavFirstLevel();
      closeBtn.on('click', function (e) {
        e.preventDefault();
        deactivateNavLinks();
        deactivateNavLists($('.level1 ul'));
        navFirstLevel.addClass('slide-out-to-right');
        closeBtn.hide();
        burger.show();
        showScroll();
        setTimeout(function () {
          navFirstLevel.removeClass('active-mobile-nav slide-out-to-right');
        }, 500);
      });
    }
    function buildMobileNavBurger() {
      var mobileNav = getMobileNav();
      var burger = '<div class="mobile-burger"><span class="mobile-burger-item"></span><span class="mobile-burger-item"></span><span class="mobile-burger-item"></span></div>';
      mobileNav.prepend(burger);
    }
    function buildMobileNavCloseBtn() {
      var closeBtn = getMobileNavCloseBtn();
      var closeBtnContainer = getMobileNav();
      addCloseBtn(closeBtn, closeBtnContainer);
    }
    function getMobileNavBurger() {
      return $('.navigation .mobile-burger');
    }
    function getMobileNavCloseBtn() {
      return $('.navigation .close-icon');
    }
    function getNavFirstLevel() {
      var navFirstLevel = $('.navigation .component-content > ul');
      return navFirstLevel;
    }
    function getMobileNav() {
      var mobileNav = $('#header .navigation .mobile-nav');
      return mobileNav;
    }
    manageHeaderOnScroll();
    function manageHeaderOnScroll() {
      // hideHeaderSub();
      if (MOBILE_BP.matches) {
        slideHeader();
      }
    }
    function slideHeader() {
      var header = getHeader();
      var scrollPos = 0;
      var HOLD_UP = 5;
      var navbarHeight = $('#header').outerHeight();
      DGS.Scroller.getInstance().register(function () {
        if (mobileNavClosed) {
          hasScrolled();
        }
      });
      function hasScrolled() {
        var currentScrollPos = $(window).scrollTop();
        if (Math.abs(scrollPos - currentScrollPos) <= HOLD_UP) {
          return;
        }
        if (currentScrollPos > scrollPos && currentScrollPos > navbarHeight) {
          header.addClass('slide-up');
        } else {
          if (currentScrollPos + $(window).height() < $(document).height()) {
            header.removeClass('slide-up');
          }
        }
        scrollPos = currentScrollPos;
      }
    }

    // function hideHeaderSub() {
    //   $(window).scroll(function () {
    //     let headerSub = getHeaderSub();
    //     if ($(window).scrollTop() > 0) {
    //       headerSub.addClass('fade-out');
    //     } else {
    //       headerSub.removeClass('fade-out');
    //     }
    //   });
    // }

    function getHeader() {
      var header = $('#header');
      return header;
    }
    function getHeaderSub() {
      var headerSub = $('#header .two-section-grid .section-two');
      return headerSub;
    }
    function manageMenuAccessibility() {
      setMenuBarRole();
      removeListItemRole();
      setMenuItemRole();
      setMenuRole();
      setMenuLabel();
      manageMenuItemsWithSubmenu();
    }
    function setMenuBarRole() {
      var menubar = $('.component.navigation > .component-content > ul');
      menubar.attr('role', 'menubar');
    }
    function removeListItemRole() {
      var listItems = $('.component.navigation > .component-content > ul li');
      listItems.attr('role', 'none');
    }
    function setMenuItemRole() {
      var menuLinks = getMenuLinks();
      menuLinks.attr('role', 'menuitem');
    }
    function setMenuRole() {
      var menuLists = getMenuLists();
      menuLists.attr({
        role: 'menu'
      });
    }
    function setMenuLabel() {
      var menuLists = getMenuLists();
      menuLists.each(function () {
        var label = $(this).parent().children('a').text();
        $(this).attr({
          'aria-label': label
        });
      });
    }
    function manageMenuItemsWithSubmenu() {
      var items = getMenuItemsWithSubmenu();
      items.map(markMenuItemsWithSubmenu);
      items.map(showIfSubmenuIsOpen);
    }
    function getMenuLinks() {
      return $('.component.navigation > .component-content > ul li a');
    }
    function getMenuLists() {
      return $('.component.navigation > .component-content > ul li.level1 ul');
    }
    function getMenuItemsWithSubmenu() {
      var menuItems = getMenuLinks();
      var menuItemsWithSubmenu = [];
      menuItems.each(function () {
        var menuItem = $(this);
        if (menuItem.siblings('ul').length > 0) {
          menuItemsWithSubmenu.push(menuItem);
        }
      });
      return menuItemsWithSubmenu;
    }
    function markMenuItemsWithSubmenu(menuItem) {
      menuItem.attr({
        'aria-haspopup': 'true',
        'aria-expanded': 'false'
      });
    }
    function showIfSubmenuIsOpen(menuItem) {
      menuItem.on('click', function () {
        if (menuItem.parent().hasClass('level1')) {
          allSubmenusAreClosed();
          if (menuItem.hasClass('active')) {
            menuItem.attr('aria-expanded', 'true');
            menuItem.parent().find('.level2 > a.active-nav-link[aria-haspopup="true"]').attr('aria-expanded', 'true');
          }
        }
        if (menuItem.parent().hasClass('level2')) {
          menuItem.attr('aria-expanded', 'true');
        }
      });
    }
    function allSubmenusAreClosed() {
      var items = getMenuItemsWithSubmenu();
      items.map(submenuIsClosed);
    }
    function submenuIsClosed(item) {
      item.attr('aria-expanded', 'false');
    }
  });
})(jQuery, window.DGS);